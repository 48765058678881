import { z } from "zod";

import { type TaskTypeEnum } from "./baseTaskSettings";

/**
 * 🚨 everything must be optional or have a default value
 */

const base = z.object({
  password: z
    .string()
    .optional()
    .describe(
      "TODO: Migrate to use this one instead of the one on the pg schema",
    ),
});

export const taskInstanceSettings = {
  WORD: base.extend({
    type: z.literal("WORD").default("WORD"),
  }),
  WORD2: base.extend({
    type: z.literal("WORD2").default("WORD2"),
  }),
  EXIT: base.extend({
    type: z.literal("EXIT").default("EXIT"),
  }),
  AICHAT: base.extend({
    type: z.literal("AICHAT").default("AICHAT"),
  }),
  ICEBREAKER: base.extend({
    type: z.literal("ICEBREAKER").default("ICEBREAKER"),
  }),
  AIGRADER: base.extend({
    type: z.literal("AIGRADER").default("AIGRADER"),
    studentAccessMode: z
      .enum(["INSTANT", "AFTER_APPROVAL"])
      .default("AFTER_APPROVAL"),
  }),
  DICTATION: base.extend({
    type: z.literal("DICTATION").default("DICTATION"),
  }),
  CLOZE: base.extend({
    type: z.literal("CLOZE").default("CLOZE"),
    studentView: z.enum(["DRAGANDDROP", "SPELL"]).default("DRAGANDDROP"),
  }),
  EXAM: base.extend({
    type: z.literal("EXAM").default("EXAM"),
  }),
  SCATTERGORIES: base.extend({
    type: z.literal("SCATTERGORIES").default("SCATTERGORIES"),
  }),
} satisfies Record<TaskTypeEnum, z.ZodTypeAny>;

export const TaskInstanceSettingsSchema = z.discriminatedUnion("type", [
  taskInstanceSettings.WORD,
  taskInstanceSettings.WORD2,
  taskInstanceSettings.EXIT,
  taskInstanceSettings.AICHAT,
  taskInstanceSettings.ICEBREAKER,
  taskInstanceSettings.AIGRADER,
  taskInstanceSettings.DICTATION,
  taskInstanceSettings.CLOZE,
  taskInstanceSettings.EXAM,
  taskInstanceSettings.SCATTERGORIES,
]);

export type TaskInstanceSettings = z.infer<typeof TaskInstanceSettingsSchema>;
