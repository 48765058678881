"use client";

import { $getRoot } from "lexical";
import { AlertCircleIcon, ChevronRight, FlagIcon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { useLocalStorage } from "usehooks-ts";

import { LexicalRender } from "~/components/blocks/RichText";
import { DashboardHeader } from "~/components/header";
import { DEFAULT_EMPTY_LEXICAL } from "~/components/lexical/editors/rich-text/default-lexical";
import { RichTextEditor } from "~/components/lexical/editors/rich-text/editor";
import { Link } from "~/components/link";
import { LoadingButton } from "~/components/loading-button";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { VideoEmbed } from "~/components/video-embed";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { cn } from "~/lib/utils";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import { type PartialSubject } from "~/server/cms/subjects";
import {
  type AiGraderInsertTaskSchema,
  type AiGraderSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "./shared";

const MIN_CHAR_LIMIT = 50;

export type TaskAiGraderProps = TaskProps<
  AiGraderInsertTaskSchema | AiGraderSelectSchema
> & {
  subject?: PartialSubject;
};

export const TaskAiGrader = ({
  task,
  taskInstance,
  preview,
  subject,
}: TaskAiGraderProps) => {
  const [done, setDone] = useState<string | false>(false);
  const [loading, setLoading] = useState(false);

  const [studentTextRawLexical, setStudentTextRawLexical] =
    useLocalStorage<string>(
      `@nextgen/task-aigrader-lexical-${taskInstance.id}`,
      JSON.stringify(DEFAULT_EMPTY_LEXICAL),
    );
  const [studentText, setStudentText] = useLocalStorage(
    `@nextgen/task-aigrader-text-${taskInstance.id}`,
    "",
  );

  const hasEnoughChars = studentText.length >= MIN_CHAR_LIMIT;

  const genericErrorDescription = useTranslation("common.something-went-wrong");
  const enoughCharsText = useTranslation(
    "task.aigrader.enough-chars",
    "Skriv minst {limit} tecken för att skicka in.",
    {
      limit: MIN_CHAR_LIMIT,
    },
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (preview) {
      return;
    }
    if (!hasEnoughChars) {
      toast.error(enoughCharsText);
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: task.type,
      studentText,
      studentTextRawLexical,
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      logger.error(res.error);
      setLoading(false);
      return;
    }
    const result = await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId: taskInstance.id,
      groupId: taskInstance.groupId,
    });
    setLoading(false);
    if (result.taskResultIds[0]) {
      setDone(result.taskResultIds[0]);
    }
    setStudentTextRawLexical(JSON.stringify(DEFAULT_EMPTY_LEXICAL));
    setStudentText("");
  }

  return (
    <div className="mx-auto h-full w-full max-w-7xl pb-12">
      <form onSubmit={handleSubmit}>
        {!preview && <DashboardHeader heading={task.name} />}
        {done ? (
          <PostSubmit
            groupId={taskInstance.groupId}
            preview={preview}
            taskResultId={done}
          />
        ) : (
          <div>
            <div className="bg-background mx-auto max-w-3xl px-4 shadow-lg">
              <Accordion
                type="single"
                collapsible
                className="w-full"
                defaultValue="open"
              >
                <AccordionItem value="open" className="border-0">
                  <AccordionTrigger>
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.instructions-for-task">
                      Instruktioner för uppgiften
                    </Translation>
                  </AccordionTrigger>
                  <AccordionContent className="px-8 pb-8">
                    {task.taskTextRawLexical ? (
                      <LexicalRender
                        content={task.taskTextRawLexical as unknown}
                        noProse
                        className="prose-sm dark:prose-invert"
                      />
                    ) : (
                      <div className="prose-sm whitespace-pre-line">
                        {task.taskText}
                      </div>
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="grid gap-2 px-2">
              <div>
                <Label htmlFor="student-text">
                  <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.your-text">
                    Din text
                  </Translation>
                </Label>
                <RichTextEditor
                  initialEditorState={studentTextRawLexical}
                  specialChars={subject?.specialChars}
                  onChange={(editorState) => {
                    const plainText = editorState.read(() =>
                      $getRoot().getTextContent(),
                    );
                    setStudentText(plainText);
                    setStudentTextRawLexical(
                      JSON.stringify(editorState.toJSON()),
                    );
                  }}
                />
                {!preview && (
                  <LoadingButton
                    isLoading={loading}
                    disabled={!!done || loading || !hasEnoughChars}
                    type="submit"
                    className="mt-2"
                  >
                    <Translation id="common.turn-in">Lämna in</Translation>
                  </LoadingButton>
                )}
                {!hasEnoughChars && (
                  <p className="text-muted-foreground pt-2 text-xs">
                    <AlertCircleIcon className="mr-2 inline h-4 w-4" />
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.write-at-least-prefix">
                      Skriv minst
                    </Translation>{" "}
                    {MIN_CHAR_LIMIT}{" "}
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.write-at-least-suffix">
                      tecken för att skicka in.
                    </Translation>{" "}
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.you-have-written-prefix">
                      Du har skrivit
                    </Translation>{" "}
                    <strong>{studentText.length}</strong>{" "}
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.you-have-written-suffix">
                      tecken
                    </Translation>
                    .
                  </p>
                )}
              </div>
              <div>
                <AnonymousAlert />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

function AnonymousAlert() {
  const [open, setOpen] = useState(false);
  return (
    <Alert>
      <FlagIcon className="h-4 w-4" />
      <AlertTitle>
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-title">
          Anonymisera texten
        </Translation>
      </AlertTitle>
      <AlertDescription>
        <div
          className={cn(
            "prose dark:prose-invert overflow-hidden transition-[max-height] duration-500",
            {
              "max-h-20": !open,
              "fade-out-mask": !open,
              "max-h-[9999px]": open,
            },
          )}
        >
          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-part-one">
              Nextgens utvecklare kan behöva titta på texten för att felsöka,
              analysera och ändra vår AI-funktionalitet. Vi kan aldrig se namnet
              på användare eller vilka skola ni går på - ni är anonyma. När ni
              skriver er text - anonymisera den också!
            </Translation>
          </p>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-part-two">
              Er text kommer aldrig att &quot;kommas ihåg&quot; av vår AI, så
              även om du skulle skriva riktigt namn så händer ingenting.
            </Translation>
          </p>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-place">
              Vill du skriva var du bor? Byt ut ditt ortsnamn till något av
              följande:
            </Translation>
          </p>
          <ul>
            <li>Nordsjöstad</li>
            <li>Silverbäck</li>
            <li>Granitkulle</li>
            <li>Björndal</li>
          </ul>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-name">
              Vill du skriva vad du heter? Byt ut ditt riktiga namn mot någon av
              följande:
            </Translation>
          </p>

          <ul>
            <li>Erik</li>
            <li>Daniel</li>
            <li>Ali</li>
            <li>Ravi</li>
            <li>Anna</li>
            <li>Yasmin</li>
            <li>Maria</li>
            <li>Zara</li>
          </ul>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-school">
              Vill du berätta om din skola? Byt ut skolans namn mot någon av
              följande:
            </Translation>
          </p>
          <ul>
            <li>Solgårdens Gymnasium</li>
            <li>Västra Akademin</li>
            <li>Ängslyckans Grundskola</li>
          </ul>
        </div>
        <Button
          onClick={() => setOpen(!open)}
          variant="ghost"
          size="sm"
          type="button"
        >
          {open ? (
            <Translation id="common.show-less">Visa mindre</Translation>
          ) : (
            <Translation id="common.show-more">Visa mer</Translation>
          )}
        </Button>
      </AlertDescription>
    </Alert>
  );
}

interface PostSubmitProps {
  groupId: string | number;
  preview?: boolean;
  taskResultId: string;
}

export const PostSubmit = ({
  groupId,
  preview,
  taskResultId,
}: PostSubmitProps) => {
  return (
    <div className="mx-auto flex max-w-xl flex-col gap-4">
      <VideoEmbed
        src="/creattie/cats.mp4"
        className="mx-auto mb-2 aspect-square h-72 w-72 max-w-full rounded-full object-cover sm:h-96 sm:w-96"
      />
      <p className="text-center">
        <Translation id="task.student.aigrader.postsubmit.text">
          Du har nu lämnat in svar på uppgiften. Nedanför kan du se din text.
        </Translation>
      </p>
      <div className="flex flex-row justify-center gap-2">
        <Link
          href={`/elev/${groupId}/ai-grader/${taskResultId}`}
          className={cn({
            "pointer-events-none": preview,
          })}
        >
          <Button variant="outline" disabled={preview}>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.see-your-result">
              Se din inlämnade text
            </Translation>
          </Button>
        </Link>
        <Link
          href={`/elev/${groupId}`}
          className={cn({
            "pointer-events-none": preview,
          })}
        >
          <Button variant="ghost" disabled={preview}>
            <Translation id="common.back-to-group">
              Tillbaka till gruppen
            </Translation>{" "}
            <ChevronRight />
          </Button>
        </Link>
      </div>
    </div>
  );
};
