"use client";

import { useEffect, useState } from "react";
import { z } from "zod";
import { useStore } from "zustand";

import { Input } from "~/components/ui/input";
import { Progress } from "~/components/ui/progress";
import { type SpellOcclusion, baseTaskSettings } from "~/lib/baseTaskSettings";
import { saveTaskInstanceResult } from "~/server/actions/tasks";

import { PostSubmit } from "../shared";
import { CompletedTarget } from "./completed-target";
import { useClozeTaskStore } from "./provider";

export const ClozeTaskSpellView = ({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) => {
  const Store = useClozeTaskStore();
  const { occlusions, parsedClose, preview } = useStore(Store, (s) => ({
    occlusions: s.occlusions,
    parsedClose: s.parsedClose,
    preview: s.preview,
  }));

  const [done, setDone] = useState(false);
  const [_loading, setLoading] = useState(false);

  async function handleDone() {
    if (taskInstanceId) {
      setLoading(true);
      if (!preview) {
        await saveTaskInstanceResult({
          summary: baseTaskSettings.CLOZE.resultZod.parse({
            type: "CLOZE",
            occlusions,
            view: "SPELL",
          }),
          taskInstanceId,
          groupId,
        });
      }
      setDone(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    const done = occlusions.every((o) => o.completed);
    if (done) {
      void handleDone();
    }
  }, [occlusions]);

  // I don't really like this, but it's the easiest way to keep track of
  // which occlusions have been used.
  const usedIndexes: number[] = [];

  return (
    <div className="md:grid md:grid-cols-3 md:gap-4">
      <div className="md:col-span-2">
        <div className="bg-background rounded-xl p-4 shadow-lg">
          {parsedClose.root.children.map((paragraph, i) => {
            return (
              <div key={i} className="whitespace-break-spaces py-1">
                {paragraph.children.map((child, j) => {
                  if (child.type === "occluded") {
                    const occlusion = occlusions.find(
                      (occlusion) =>
                        occlusion.label === child.text &&
                        !usedIndexes.includes(occlusion.index),
                    );
                    if (!occlusion) {
                      return child.text;
                    }
                    usedIndexes.push(occlusion.index);
                    if (occlusion.view !== "SPELL") {
                      return null;
                    }
                    return <InputWithHUD key={j} occlusion={occlusion} />;
                  }
                  if (child.type === "text") {
                    return child.text;
                  }
                  return null;
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="pt-4">
        {done ? (
          <PostSubmit groupId={groupId} preview={preview} taskType="CLOZE" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

interface InputWithHUDProps {
  occlusion: SpellOcclusion;
}

const InputWithHUD = ({ occlusion }: InputWithHUDProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);

  const Store = useClozeTaskStore();
  const { guessCloze } = useStore(Store, (s) => ({
    guessCloze: s.guessCloze,
  }));

  useEffect(() => {
    if (occlusion.completed) {
      setTimeout(() => {
        setShowCompleted(true);
      }, 1000);
    }
  }, [occlusion.completed]);

  if (showCompleted) {
    return <CompletedTarget text={occlusion.input} />;
  }

  const showHud = isFocused || occlusion.completed;

  return (
    <div className="relative inline-block">
      <Input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="inline-block w-24"
        value={occlusion.input}
        onChange={(e) => {
          guessCloze(occlusion.index, e.target.value);
        }}
        readOnly={occlusion.completed}
        data-testid={`cloze-spell-input-${occlusion.index}`}
      />
      {showHud && (
        <div className="absolute left-1/2 top-full z-50 mt-2 -translate-x-1/2">
          <div className="bg-background flex w-[300px] flex-row justify-between gap-2 rounded border p-2 shadow-lg">
            <Progress
              className="text-secondary mt-2 w-full"
              value={Number(occlusion.percentageCorrect ?? 0) * 100}
            />
            {occlusion.completed ? (
              <>🎉</>
            ) : (
              `${floatToPercent(occlusion.percentageCorrect)}%`
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function floatToPercent(f: unknown) {
  const p = z.coerce.number().min(0).max(1).parse(f);
  return Math.round(p * 100);
}
