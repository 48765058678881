"use client";

import { DashboardHeader } from "~/components/header";
import { taskInstanceSettings } from "~/lib/taskInstanceSettings";

import { ClozeTaskDragAndDropView } from "./drag-and-drop";
import { Preview } from "./preview";
import { type Props, Provider } from "./provider";
import { ClozeTaskSpellView } from "./spell";

export const TaskCloze = ({ task, taskInstance, preview }: Props) => {
  const settings = taskInstanceSettings.CLOZE.parse(taskInstance.settings);
  const view = settings.studentView;
  return (
    <Provider ctx={{ task, preview, view }}>
      <div className="container">
        {!preview && <DashboardHeader heading={task.name}></DashboardHeader>}
      </div>
      {preview ? (
        <Preview taskInstance={taskInstance} />
      ) : (
        <>
          {view === "DRAGANDDROP" && (
            <ClozeTaskDragAndDropView
              groupId={taskInstance.groupId}
              taskInstanceId={taskInstance.id}
            />
          )}
          {view === "SPELL" && (
            <ClozeTaskSpellView
              groupId={taskInstance.groupId}
              taskInstanceId={taskInstance.id}
            />
          )}
        </>
      )}
    </Provider>
  );
};
