"use client";

interface CompletedTargetProps {
  text: string;
}

export function CompletedTarget({ text }: CompletedTargetProps) {
  return (
    <span className="rounded border border-green-500 bg-green-200 p-1 text-black">
      {text}
    </span>
  );
}
